<template>
  <div>

    <div class="signup">
      <div class="header">
        <div class="top flex-between">
          <div class="left">
            <img src="../../assets/public/logo.png" alt="" @click="tourl">
            <p>|</p>
            <div>
              <p>
                <img src="../../assets/signup/one.png" alt="">
                <span>我要报名</span>
              </p>
              <p>-------</p>
              <p>
                <img src="../../assets/signup/two.png" alt="">
                <span>我要支付</span>
              </p>
            </div>
          </div>
          <div class="right flex-around">
            <img src="../../assets/public/phone.png" alt="">
            <div class="time">
             <p class="t1">{{timePhone}}</p>
              <p class="t2" style="width:220px">{{timekf}}</p>
            </div>
          </div>
        </div>

      </div>

      <div class="main">
        <div class="mleft">
          <div class="m1">
            <h4>订单信息</h4>
            <div>
              <div class="img">
                <img :src="view.goods_pic" alt="">
              </div>

              <div class="content">
                <p>{{view.goods_name}}</p>
                <div>
                  <p>
                    <img src="../../assets/signup/wz.png" alt="">
                    <span>集合站点：</span>
                  </p>
                  <div>
                    <el-radio v-model="radio" :label="item" v-for="item in view.place_address" :key="item">{{item}}
                    </el-radio>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- m2 -->
          <div class="m2">
            <h4>出行人信息 <span>用于购买保险或入住酒店</span></h4>
            <div class="bm">
              <div class="b1">
                <p style="font-size: 16px;">报名人数<span style="color: #FF7C00;">*</span></p>
                <p>
                  <el-input-number v-model="adult" :min="0" :max="10" size="mini" disabled></el-input-number>
                  <span>成人</span>
                </p>
                <p>
                  <el-input-number v-model="child" :min="0" :max="10" size="mini" disabled></el-input-number>
                  <span>儿童</span> <span class="s1">12周岁(不含)以内</span>

                </p>

              </div>
              <div class="tip">
                <p>1、为了您的保险生效及顺利出行，请务必保证填写项与出游所持证件一致。</p>
                <p>2、出行人类型（成人/儿童）请与报名订单的成人/儿童保持一致；儿童如无相关证件，证件类型请选择“其他”。 </p>
                <p>3、为了保障您的合法权益，请准确、完整的填写出行人信息。因填写信息不完整、不准确造成的保险拒赔等问题，我司 不承担相应责任。</p>
              </div>
              <div class="b2">
                <p>
                  <span style="font-weight: 500;color: #333;margin-right: 10px;">常用出行人</span>
                  <img src="../../assets/signup/add.png" alt="">
                  <span style="color: #FF4D51;cursor: pointer;" @click="dia=true">添加</span>
                </p>

              </div>
              <div class="b2">
                <!-- <el-checkbox-group > -->
                <el-checkbox :label="item" v-for="(item,i) in user_contact" :key="i"
                  @change='change(item)' v-model="item.checked">
                  {{item.real_name}}
                </el-checkbox>
                <!-- </el-checkbox-group> -->

              </div>
              <div class="rbox">
              <template  v-for="(item,i) in user_contact">
                <div class="box" v-if="item.checked" :key="i">
                  <div class="b1 flex-between">
                    <p>
                      <span>{{item.real_name}}</span>
                      <span>{{item.sex | sexshow}}</span>
                      <span>{{item.type_id | oldtype}}</span>
                    </p>
                    <p>{{item.phone}}</p>
                  </div>
                  <div class="b1">
                    <p>证件类型</p>
                    <p>{{item.card_type | cardtype}}</p>
                  </div>
                  <div class="b1">
                    <p>证件号码</p>
                    <p>{{item.card_number}}</p>
                  </div>
                  <div class="b2 b1">
                    <div>
                      <p>出生日期</p>
                      <p>{{item.birthday}}</p>
                    </div>

                    <div class="edit">
                      <img src="../../assets/user/edit2.png" alt="" @click="edit(item)">
                      <img src="../../assets/user/del.png" alt="" @click="del(item.id,i)">
                    </div>
                  </div>
                </div>
              </template>
              </div>
            </div>
          </div>
          <!-- m3 -->
          <div class="m3">
            <h4>预订人信息 <span>用于接受订单反馈</span></h4>
            <div class="form">
              <div class="inp">
                <p>姓名<span>*</span></p>
                <input type="text" placeholder="请输入真实姓名" v-model="form.name">
              </div>
              <div class="inp">
                <p>手机<span>*</span></p>
                <input type="text" placeholder="用于接收订单及支付信息" v-model="form.phone">
              </div>
              <!-- <div class="inp">
                <p>邮箱</p>
                <input type="text" placeholder="用于接收出团通知书" v-model="form.email">
              </div> -->
              <div class="inp">
                <p>备注</p>
                <textarea placeholder="如有特殊情况可备注说明" v-model="form.note"></textarea>
              </div>
            </div>
          </div>
          <!-- m4 -->
          <div class="m3">
            <h4>保险方案</h4>
            <div class="form">
              <p>该旅行产品中已赠送基础保险方案，请知悉。</p>
            </div>
          </div>
          <!-- m5 -->
          <div class="m5 flex-between">
            <p>
              <img src="@/assets/public/check1.png" v-if="read" @click="read = false" />
              <img src="@/assets/public/check2.png" v-if="!read" @click="read = true" />
            </p>
            <p>
              提交订单表示我已阅读并接受。点击查看
              <span class="hand" @click="lookht(14,'报名须知')">《趣游网报名须知》</span>
              <span class="hand" @click="lookht(13,'合同范本')">《合同范本》</span>
            </p>
            <div @click="topay">
              结算信息
            </div>
          </div>

        </div>
        <div class="mright">
          <div>
            <h4>结算信息</h4>
            <div class="all">
              <div class="a1 flex-between">
                <p>出行日期</p>
                <p>{{view.buy_date}}</p>
              </div>
              <div class="a1 flex-between">
                <p>出行人数</p>
                <p><span>成人*{{adult}}</span><span> 儿童*{{child}}</span></p>
              </div>
              <div class="a1 flex-between" v-if="order_type === 2">
                <p>定金</p>
                <p style="color: #FF4C12;font-weight: 500;">￥{{presale_order_price}}</p>
              </div>
              <div class="a1 flex-between" v-else>
                <p>产品总价</p>
                <p style="color: #FF4C12;font-weight: 500;">￥{{allprice}}</p>
              </div>
              <div class="a1 flex-between" v-if="order_type === 2">
                <p>尾款</p>
                <p style="color: #FF4C12;font-weight: 500;">￥{{presaleOrderPrice}}</p>
              </div>
              <div class="a1 flex-between" v-if="order_type === 1">
                <p>特价订单</p>
                <p style="color: #FF4C12;font-weight: 500;">-￥{{quCardPreferentPrice}}</p>
              </div>
              <div class="line"></div>
              <div class="a1 flex-between">
                <p>{{order_type === 2 ? '尾款' : ''}}优惠券</p>
                <p class="yhj">
                  <span style="color: #FF4C12;font-weight: 500;margin-right: 10px;">

                    <template v-if="yhjprice"> ¥{{yhjprice}}</template>
                    <template v-else> {{user_coupon.length}}张可用</template>
                  </span>
                  <img src="../../assets/signup/down.png" @click="openyhq">

                </p>
                <div class="yhjlist" v-if="yhj">
                  <div v-if="user_coupon.length==0" style="text-align:center;width:100%;color:#666">
                    暂无可用的优惠券
                  </div>
                  <div class="couitem" v-for="(item,i) in user_coupon.slice((page-1)*pageSize,page*pageSize)" :key="i"
                  @click="yhjcheck(item)">
                    <div class="c1">
                      <p class="t1">{{item.name}}</p>
                      <p class="t2">{{item.extra}}
                        <el-popover placement="top-start" title="规则" width="300" trigger="hover" :content="item.use_range">
                          <span slot="reference" style="cursor:pointer">规则</span>
                        </el-popover>

                      </p>
                      <p class="t3">有效期至<span>{{item.out_time}}</span></p>
                    </div>
                    <div class="c2">
                      <p><span>￥</span>{{item.total}}</p>
                    </div>
                  </div>
                  <el-pagination small layout="prev, pager, next" style="margin-top:20px"
                    :total="total" @current-change="handleCurrentChange"
                    :page-size="pageSize" :hide-on-single-page="total<=pageSize">
                </el-pagination>
                </div>
              </div>
              <div class="a1 flex-between">
                <p class="jf">
                  <span>奖励积分</span>
                  <img src="../../assets/signup/question.png" @mouseover="jf=1" @mouseleave="jf=0">
                  <div class="sm" v-show="jf==1">
                    <p>奖励积分的抵扣规则如下： </p>
                    <p> a.每100奖励积分抵扣1元</p>
                    <p> b.使用奖励积分数量需为100的整数倍 </p>
                    <p> c.最高只能抵扣订单实际支付金额的50%</p>
                    <p>d.支付成功后个人原因取消订单，使用的奖励积分不予返还；趣游原因取消订单，使用的奖励积分将原数返还</p>
                  </div>
                </p>
                <p class="yhj">
                  <!-- <span>使用</span> -->
                  <!-- <input type="number" placeholder="请输入积分" :max="maxintegrals" min="0" :step="100" v-model="userjf"
                    @keyup="loadNumber($event)"> -->
                   <el-switch
                    v-model="value"
                    active-color="#FF4C12"
                    inactive-color="#d1cfef"
                    active-value="1"
                    @change="changeuserjf"
                    :disabled="disabled"
                    inactive-value="0">
                    </el-switch>
                </p>
              </div>
              <p style="font-size: 12px;color: #ff4c12;">您当前有积分{{view.integral}}，当前活动最多可抵扣￥{{maxintegrals/100}}</p>
              <!-- <div class="a1 flex-between" v-if="view.user_card!=''">
							<p>{{view.user_card.card_id | vipcard}}</p>
							<p>
								<el-switch
								v-model="value"
								active-color="#FF4C12"
								inactive-color="#d1cfef"
								active-value="1"
								inactive-value="0">
							  </el-switch>
							</p>
						</div> -->

            </div>
            <div class="flex-between last">
              <p>应付金额</p>
              <p style="color: #FF4C12;font-weight: 500;font-size: 24px;">￥{{lastprice}}</p>
            </div>
          </div>

        </div>
      </div>
      <el-dialog :visible.sync="dia" title="常用出行人" width="450px" @close="close">
        <diaUserList :form="form2" @getlist="getlist"></diaUserList>
      </el-dialog>

      <diaContract ref="contracts"></diaContract>

    </div>
    <myfooter></myfooter>
  </div>
</template>

<script>
  import {
    signUpViewAPI,
    signUpAPI,
    personinfoAPI,
    taskConfirmr,
    updateOrderAPI
  } from "@/api/signup"
  import {
    orderViewAPI,
    deluseroutdoorAPI,userCouponList
  } from "@/api/user"
  import {
    tripListAPI,goodsViewAPI
  } from "@/api/details"
  import myfooter from '@/components/footer.vue'
  import  diaUserList from "@/components/com/diaUserList"
  import  diaContract from "@/components/com/diaContract"
  export default {

    components: {
      myfooter,diaUserList,diaContract
    },
    data() {
      return {
        disabled: false,
        page:1,
        pageSize:3,
        diacontractshow:false,
        is_special:"1",
        url:"",
        read: false,
        value: '0',
        jf: 0,
        yhj: false,
        id: 0,
        oid: null,
        order: {},
        osn: "",
        view: {},
        adult: 0,
        child: 0,
        checkList: [],
        radio: '1',
        form: {
          phone: "",
          name: "",
          // email: '',
          note: ""
        },
        user_contact: [],
        user_coupon: [],
        couponid: null,
        price_integral: {
          id: 1,
          integral: 100,
          msg: "1积分多于多少钱",
          price: 1
        },
        userjf: 0,
        form2: {
          real_name: "",
          sex: "1",
          card_type: 1,
          card_number: "",
          birthday: "",
          type_id: "1",
          phone: ""
        },
        dia: false,
        yhjprice: 0,
        options: [{
          value: 1,
          label: '身份证'
        }, {
          value: 2,
          label: '军官证'
        }, {
          value: 3,
          label: '港澳通行证'
        }, {
          value: 4,
          label: '护照'
        }, {
          value: 5,
          label: '回乡证'
        }, {
          value: 6,
          label: '台胞证'
        }],
        checkTravelsPriceList: [],
        useQuCardFinalPrice: 0,
        order_type: 0,
        presale_other_order_price: 0,
        presale_order_price: 0,
      }
    },
    created() {
      const id = this.$route.query.id
      const oid = this.$route.query.oid
      this.id = id
      this.getsignupview(id)
      this.confirmR()
      this.getperson()


      if (oid) {
        this.oid = oid

      }

    },
    filters: {
      oldtype(t) {
        if (t == 1) {
          return "成人"
        } else {
          return "儿童"
        }
      },
      cardtype(t) {
        let arr = ["未知", "身份证", "军官证", "港澳通行证", "护照", "回乡证", "台胞证"]
        let i = parseInt(t)
        return arr[i]
      },
      vipcard(c) {
        if (c == 1) {
          return "月卡"
        } else if (c == 2) {
          return "季卡"
        } else if (c == 3) {
          return "年卡"
        }
      }
    },
    computed: {
        timePhone(){
        var week=(new Date()).getDay()
         if(week==0 || week==6){
            return "18817885198"
         }

        var hour=(new Date()).getHours()
        if (hour>=9 && hour<18) {
          return "400 888 9318"
        } else {
          return "18817885198"
        }
      },
      timekf(){
         var week=(new Date()).getDay()
         if(week==0 || week==6){
            return "客服时间：18:00~09:00（非工作时段）"
         }
         var hour=(new Date()).getHours()
        if (hour>=9 && hour<18) {
          return "客服时间：09:00~18:00（工作日）"
        } else {
          return "客服时间：18:00~09:00（非工作时段）"
        }
      },
      maxintegrals(){
        if ((this.allprice / 2)>this.integrals) { //如果总价大于积分

          return this.integrals*100
        }else{
          var i=Math.floor(this.allprice) *100 / 2
           return Math.floor(i)
        }
      },
      integrals() {
        if (this.price_integral.price) {
          var hs = this.price_integral.price / this.price_integral.integral
          var num = Math.floor(this.view.integral * hs)
          return num
        } else {
          return 0
        }
      },
      allprice() {
        var a = this.view.adult_price * this.adult
        var c = this.view.children_price * this.child
        return a + c
      },
      presaleOrderPrice() {
        let result = this.presale_other_order_price
        if (this.value) {
          result = result - this.maxintegrals
        }
        result = result - this.yhjprice
        if (result < 0) return 0
        return result
      },
      quCardPreferentPrice() {
        if (this.checkTravelsPriceList.length < 1) {
          return 0
        }
        return this.allprice - this.useQuCardFinalPrice
      },
      lastprice() {
        if (this.order_type === 2) {
          return this.useQuCardFinalPrice
        } else {
          let all = this.allprice
          var hs = this.price_integral.price / this.price_integral.integral
          var jf = this.userjf * hs
          if (this.checkTravelsPriceList.length) {
            // return this.useQuCardFinalPrice
            all = this.useQuCardFinalPrice
          }
          if (this.value) {
            all = all - jf
          }
          all = all - this.yhjprice
          // var all = this.allprice - jf - this.yhjprice - this.useQuCardFinalPrice
          return all < 0 ? 0 : all;
        }
      },
    },
    watch: {
      user_contact: {
        immediate: true,
        handler(data) {
          console.log('watch user_contact', data)
        }
      }
    },
    mounted(){

    },
    methods: {
      handleCurrentChange(currentPage){
          this.page=currentPage
      },
      lookht(i){
        this.$refs.contracts.show=true
        this.$refs.contracts.getabout(i)
      },
      openyhq(){
        this.page==1

        if (this.is_special!=1) {
          if (this.order_type !== 1 && this.view.is_coupon == 0) {
            this.$message.info("此特惠班期暂不支持优惠券使用")
            return false
          }
          if (this.order_type === 1 && (this.view.special_is_coupon == 0)) {
            this.$message.info("此特价班期暂不支持优惠券使用")
            return false
          }
           this.yhj=!this.yhj
        } else {
          this.$message.info("特例商品不能选择优惠券")
        }
      },
      getgoods(id){
        goodsViewAPI({id}).then(res=>{
          this.is_special =res.cateView.is_special
        })
      },
      getuserCouponList(id){
        var data={
          is_receive: 1,
          status: 1,
          goods_id:id
        }
        userCouponList(data).then(res=>{
          this.user_coupon=res.coupon_list
          this.total=res.count
          console.log('this.user_coupon',this.user_coupon)
          if(res.coupon_list.length>0){
             this.yhjprice = res.cateView.user_coupon[0].total
              this.couponid = res.cateView.user_coupon[0].id
          }
        })
      },
      del(id,i) {
        this.$confirm('此操作将删除该出行人信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deluseroutdoorAPI({
            id
          }).then(res => {
            this.getsignupview(this.id)

            this.$message("删除成功")
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      },

      change() {
        console.log(this.user_contact)
        var a=0
        var c=0
         for (let i = 0; i < this.user_contact.length; i++) {
           if(this.user_contact[i].checked){
             if(this.user_contact[i].type_id==1){
                a=a+1
                console.log(a)
              }else{
                c=c+1
                console.log(c)
              }
           }

        }
        this.adult=a
        this.child=c
        this.confirmR()
        // this.$forceUpdate()
      },
      yhjcheck(item) {


        if ( this.couponid ) {
           this.yhjprice = "0"
            this.couponid = null
        }else{
           this.yhjprice = item.total
           this.couponid = item.id
        }
        this.yhj = false
      },

      async getperson() {
        if (this.oid == null) {
          await personinfoAPI().then(res => {
            this.form.name = res.user.real_name || res.user.nickname
            this.form.phone = res.user.phone
            // this.form.email = res.user.mailbox
          })
        } else {
          console.log(this.oid)
          this.form.name = this.order.pre_real_name
          this.form.phone = this.order.pre_phone
          // this.form.email = this.order.pre_email
          this.form.note = this.order.pre_note
        }
      },
      getlist(dia){
        this.getsignupview(this.id)

          this.dia = dia
         this.close()
      },
      close() {
          this.form2 = {
              id: null,
              real_name: "",
              sex: "1",
              card_type: 1,
              card_number: "",
              birthday: "",
              type_id: "1",
              phone: ""
          }
      },
      edit(item) {
        this.form2 = {
            id: item.id,
            real_name: item.real_name,
            sex: item.sex+"",
            card_type: item.card_type,
            card_number: item.card_number,
            birthday: item.birthday,
            type_id: item.type_id+"",
            phone:item.phone
        }
        this.dia = true
      },
      changeuserjf(e){
        if (this.order_type !== 1 && this.view.is_integral == 0) {
          this.value = 0
          this.$message.info("此特惠班期暂不支持积分使用")
          return false
        }
        if (this.order_type === 1 && (this.view.special_is_integral == 0)) {
          this.value = 0
          this.$message.info("此特价班期暂不支持积分使用")
          return false
        }
        this.userjf=e==0 ? 0 : this.maxintegrals

      },
      loadNumber: function (event) {
        console.log(event.target.value)
         var value =event.target.value
          var r = /^\+?[1-9][0-9]*$/;　　//正整数
          var str=""+value%100
        if (r.test(str)) {
          // this.$message.info("请输入100的整数倍")
          return
        }
         if (this.maxintegrals<value) {
          this.$message.info("超出最多可使用积分")
          return
        }
       this.userjf=value
        if (value < 0) {
          this.userjf = 0
        } else if (value >= this.view.integral) {
          this.userjf = this.view.integral
        }

      },
      topay() {
         console.log(this.user_contact)
        if (!this.read) {
          this.$message.info("请确认阅读内容")
          return
        }
        if (!this.adult) {
          this.$message.info("请至少选择一个成人！")
          return
        }

        this.$nextTick(()=>{
          var json=this.user_contact.filter(item=> item.checked)
          var strjson= JSON.parse(JSON.stringify(json));

           var strjson=strjson.map(item=>{
            //  delete item.checked
            //  delete item.id
            //   return item
            return {
              real_name: item.real_name,
              card_id: item.member_card_id,
              card_type: item.card_type,
              card_number: item.card_number,
              birthday: item.birthday,
              type_id: item.type_id,
              sex: item.sex,
              phone: item.phone,
              pre_note: item.pre_note || ""
            }
           })
          var obj = {
          id: this.id,
          place_address: this.radio,
          pre_phone: this.form.phone,
          // pre_email: this.form.email,
          pre_note: this.form.note,
          json: JSON.stringify(strjson),
          pre_real_name: this.form.name,
          adult_number: this.adult,
          children_number: this.child,
          discount_integral: this.userjf,
          user_coupon_id: this.couponid

        }

          signUpAPI(obj).then(res => {
            this.$message("提交成功！")
            this.$router.push({
              path: '/pay',
              query: {
                id: res.order_id
              }
            })
          })
        })
      },
      tourl() {
        this.$router.push({
          path: '/'
        })
      },
      async confirmR () {
        const travleList = []
        this.user_contact.forEach(item => {
          if (item.checked) {
            travleList.push({
              real_name: item.real_name,
              card_id: item.member_card_id,
              card_type: item.card_type,
              card_number: item.card_number,
              birthday: item.birthday,
              type_id: item.type_id,
              sex: item.sex,
              phone: item.phone,
              pre_note: item.pre_note || ""
            })
          }
        })
        const params = {
          id: this.id,
          travel_number: JSON.stringify(travleList),
          adult_number: this.adult,
          children_number: this.child
        }
        const res = await taskConfirmr(params)
        console.log(res)
        this.checkTravelsPriceList = res.price_all
        this.useQuCardFinalPrice = Number(res.order_price)
        this.order_type = res.order_type
        this.presale_other_order_price = res.presale.presale_other_order_price
        this.presale_order_price = res.presale.presale_order_price
      },
      async  getsignupview(id) {
        await signUpViewAPI({
          id,
          is_pc: 1
        }).then(res => {
          this.view = res.cateView
          this.user_contact = res.cateView.user_contact.map(item=>{
            return {
              ...item,
              checked:false
            }
          })
         this.getgoods(res.cateView.id)
          // console.log( this.user_contact,88888)
          // this.price_integral = res.cateView.price_integral
          this.radio = res.cateView.place_address[0]
          this.getuserCouponList(res.cateView.id)

        })
      },
    }
  }

</script>
<style lang="scss" scoped="scoped">
  .signup {
    background-color: #f9f9f9;

    .header {
      background: #fff9ef;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);

      .top {
        width: 1200px;
        margin: 0 auto;
        height: 82px;

        .left {
          display: flex;
          align-items: center;

          >img {
            width: 126px;
          }

          >p {
            margin: 0 30px;
          }

          >div {
            display: flex;
            align-items: center;
            color: #6d380c;

            p {
              margin: 0 10px;
              display: flex;
              align-items: center;

              span {
                font-size: 18px;
                color: #6d380c;
                margin-left: 10px;
              }
            }
          }
        }

        .right {
          width: 260px;

          img {
            width: 21px;
            height: 21px;
          }

          .t1 {
            font-size: 17px;
            font-weight: 500;
          }

          .t2 {
            font-size: 11px;
            color: #666666;
            line-height: 17px;
          }

        }

      }
    }

    .main {
      width: 1200px;
      margin: 20px auto;
      display: flex;

      .mleft {
        width: 770px;
        margin-right: 20px;
        margin-bottom: 20px;

        .m1 {
          width: 770px;
          padding: 15px;
          background-color: #fff;
          border-radius: 5px;

          h4 {
            line-height: 30px;
            margin-bottom: 10px;
          }

          >div {

            display: flex;

            .img {
              width: 330px;
              height: 160px;
              opacity: 1;
              border-radius: 4px;
              overflow: hidden;
              margin-right: 20px;

              img {
                width: 330px;
                min-height: 160px;
              }
            }

            .content {
              flex: 1;

              >p {
                font-size: 18px;
                font-weight: 500;
                ;
                color: #333333;
                line-height: 28px;
              }

              >div {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;

                p {
                  display: flex;
                  align-items: center;
                  margin-right: 10px;

                  img {
                    width: 18px;
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }

        .m2 {
          width: 770px;
          padding: 15px;
          background-color: #fff;
          border-radius: 5px;
          margin-top: 20px;

          h4 {
            line-height: 30px;
            margin-bottom: 10px;

            span {
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              line-height: 22px;
              margin-left: 10px;
            }
          }

          .bm {
            .b1 {
              display: flex;

              >p {
                margin-right: 35px;

                >span {
                  margin-left: 10px;
                  color: #333;
                  font-size: 14px;
                }

                .s1 {
                  font-size: 12px;
                  color: #666666;
                  line-height: 20px;
                  margin-left: 0;
                }
              }
            }

            .tip {
              opacity: 0.81;
              font-size: 12px;
              color: #999999;
              line-height: 26px;
              margin-top: 10px;
            }

            .b2 {
              display: flex;
              flex-wrap: wrap;
              margin-top: 20px;
              font-size: 14px;

              p {
                display: flex;
                align-items: center;
                margin-right: 10px;

                img {
                  width: 18px;
                  margin-right: 5px;
                }
              }
            }

            .rbox {
              display: flex;
              flex-wrap: wrap;
              margin-top: 10px;

              .box {
                width: 350px;
                border: 1px solid #e6e6e6;
                border-radius: 7px;
                margin: 10px;
                padding: 0 10px 10px;

                .b1 {
                  display: flex;

                  margin-top: 10px;

                  p:nth-child(1) {
                    color: #333;
                    margin-right: 10px;
                  }

                  p {
                    span {
                      margin-right: 10px;
                    }
                  }

                  p:nth-child(2) {
                    color: #666;
                    margin-right: 10px;
                  }

                  >div {
                    display: flex;
                    align-items: center;
                  }

                  .edit {
                    img {
                       width: 20px;
                      height:20px;
                      margin: 0 10px;
                    }
                  }
                }

                .b2 {
                  align-items: center;
                  justify-content: space-between;
                }


              }

            }

          }
        }

        .m3 {
          width: 770px;
          padding: 15px;
          background-color: #fff;
          border-radius: 5px;
          margin-top: 20px;

          h4 {
            line-height: 30px;
            margin-bottom: 10px;

            span {
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              line-height: 22px;
              margin-left: 10px;
            }
          }

          .form {
            .inp {
              display: flex;
              align-items: center;
              margin-top: 15px;

              p {
                width: 80px;
                font-size: 14px;

                span {
                  color: #FF4C12;
                  margin-left: 2px;
                }
              }

              input {
                width: 643px;
                height: 40px;
                opacity: 1;
                background: #f8f8f8;
                border-radius: 4px;
                text-indent: 10px;
              }

              textarea {
                width: 643px;
                height: 80px;
                opacity: 1;
                background: #f8f8f8;
                border-radius: 4px;
                padding: 10px;
                line-height: 26px;
              }
            }
          }
        }

        .m5 {
          margin: 35px 0;

          p {
            font-size: 12px;

            color: #666666;
            line-height: 17px;

            span {
              color: #FF7C00;
            }
          }

          div {
            width: 321px;
            height: 45px;
            opacity: 1;
            background: #ff7c00;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
          }
        }
      }

      .mright {
        width: 410px;

        >div {
          background: #ffffff;
          border: 1px solid #ff7c00;
          border-radius: 6px;
        }

        h4 {
          line-height: 55px;
          height: 55px;
          background-color: rgba(255, 124, 0, 0.1);
          text-indent: 15px;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
        }

        .all {
          padding: 15px;

          .a1 {
            position: relative;
            font-size: 14px;
            color: #333;
            line-height: 40px;

            p:nth-child(2) {
              color: #666;
            }

            span {
              color: #666;
            }

            .yhj {
              // position: relative;
              display: flex;
              align-items: center;

              input {
                width: 94px;
                height: 30px;
                opacity: 1;
                background: #f8f8f8;
                border-radius: 3px;
                margin-left: 10px;
                text-indent: 8px;
              }

            }

            .yhjlist {
              position: absolute;
              z-index: 3;
              top: 40px;
              left: -16px;
              width: 410px;
              opacity: 1;
              background: #fff;
              border-radius: 6px;
              box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.17);
              padding: 10px;
              display: flex;
              flex-wrap: wrap;

              .couitem {
                border: 1px solid #e6e6e6;
                width: 390px;
                height: 120px;
                background: #f9f9f9;
                border-radius: 1px;
                padding: 10px;
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .c1 {
                  .t1 {
                    font-size: 16px;
                    font-weight: 600;
                    color: #333333;
                    line-height: 32px;
                  }

                  .t2 {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                    line-height: 40px;

                    span {
                      color: #FF7C00;
                      margin-left: 15px;
                    }
                  }

                  .t3 {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                    line-height: 40px;

                    span {
                      color: #333;
                      margin-left: 5px;
                    }
                  }
                }

                .c2 {
                  background: url(../../assets/user/yhj1.png) no-repeat;
                  background-size: cover;
                  width: 110px;
                  height: 110px;
                  // opacity: 1;
                  // border: 1px dashed #ff7c00;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  p {
                    font-weight: 500;
                    color: #fff;
                    font-size:24px;
                    line-height: 95px;
                    text-align: center;

                    span {
                      font-weight: 400;
                      color: #fff;
                      font-size: 14px;
                    }
                  }

                }

              }
            }

            .jf {
              img {
                margin: 5px 0 0 5px;
              }

            }

            .sm {

              position: absolute;
              bottom: 25px;
              left: 75px;
              width: 185px;
              // height: 187px;
              opacity: 1;
              background: rgba(0, 0, 0, 0.50);
              border-radius: 4px;
              padding: 5px;

              p {
                font-size: 12px;
                color: #fff;
                line-height: 18px;
                padding: 8px;
                padding: 0;
              }
            }

          }

          .line {
            height: 1px;
            opacity: 1;
            border-bottom: 1px solid #cccccc;
            margin: 15px 0;
          }
        }

        .last {
          line-height: 55px;
          height: 55px;
          background-color: rgba(255, 124, 0, 0.1);
          padding: 0 15px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
        }
      }
    }
  }


</style>
<style lang="scss">
  .el-dialog__body {
    padding: 0px 20px;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #ff7c00;
    background: #ff7c00;
  }

  .el-radio__input.is-checked+.el-radio__label {
    color: #ff7c00;
  }

</style>
